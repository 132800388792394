import { Link } from "gatsby"
import React from "react"
import Page from "../components/page"
import ResponsiveImage from "../components/responsive-image"
import ResponsiveImageCarousel from "../components/responsive-image-carousel"
import { themes } from "../styles/themes"
import paths from "../utils/paths"
import useScreenSize from "../utils/useScreenSize"

const homeCarousel = labels => [
  {
    src: "/images/home/x-armchair-architecture-to-furniture.jpg",
    caption: labels.homeFromArchitectureToFurniture,
    itemClassName: "ips-carousel-black-item",
    indicatorsClassName: "ips-carousel-black-indicators",
    position: "bottom",
    alt: labels.homeFromArchitectureToFurnitureAlt,
    imgTitle: labels.homeFromArchitectureToFurniture
  },
  {
    src: "/images/home/space-z-lobby-signature-space.jpg",
    caption: labels.homeSpaceWithSignature,
    itemClassName: "ips-carousel-black-item",
    alt: labels.homeSpaceWithSignatureAlt,
    imgTitle: labels.homeSpaceWithSignature
  },
  {
    src: "/images/home/outside-sketch-conceptual-solution.jpg",
    caption: labels.homeConceptualSolutions,
    alt: labels.homeConceptualSolutionsAlt,
    imgTitle: labels.homeConceptualSolutions
  },
  {
    src: "/images/home/cocktail-ottoman-pleasure-corner.jpg",
    caption: labels.homePleasureCorner,
    alt: labels.homePleasureCornerAlt,
    imgTitle: labels.homePleasureCorner
  },
  {
    src: "/images/home/black-mirror-eye-of-art.jpg",
    caption: labels.homeEyeOfTheArt,
    position: "bottom",
    alt: labels.homeEyeOfTheArtAlt,
    imgTitle: labels.homeEyeOfTheArt
  }
]

const homeItems = (labels, pagePaths, isSmallScreen) => [
  {
    text: labels.homeInteriorsText,
    path: pagePaths.interiors,
    src: "/images/home/interiors-the-best-life.jpg",
    alt: labels.homeInteriorsAlt,
    imgTitle: labels.homeInteriorsImgTitle
  },
  {
    text: labels.homeProductDesignText,
    path: pagePaths.productDesign,
    src: "/images/home/product-design-mens-secrets.jpg",
    alt: labels.homeProductDesignAlt,
    imgTitle: labels.homeProductDesignImgTitle
  },
  {
    text: labels.homeArchitectureText,
    path: pagePaths.architecture,
    src: "/images/home/architecture-villa-m.jpg",
    alt: labels.homeArchitectureAlt,
    imgTitle: labels.homeArchitectureImgTitle
  },
  isSmallScreen
    ? {
        text: labels.homeAuthorText,
        path: pagePaths.author,
        src: "/images/home/author-igor-prodanovic.jpg",
        position: "top",
        alt: labels.homeAuthorAlt,
        imgTitle: labels.homeAuthorImgTitle
      }
    : undefined,
  isSmallScreen
    ? {
        text: labels.homePressText,
        path: pagePaths.press,
        src: "/images/home/press-vecernji-list.jpg",
        position: "center 20%",
        alt: labels.homePressAlt,
        imgTitle: labels.homePressImgTitle
      }
    : undefined
  // {
  //   text: labels.homeProjectsText,
  //   path: pagePaths.projects,
  //   src: "/images/home/projects-easy-rider.jpg",
  //   position: "left",
  //   alt: labels.homeProjectsAlt,
  //   imgTitle: labels.homeProjectsImgTitle
  // },
  // {
  //   text: labels.homeCollaborationsText,
  //   path: pagePaths.collaborations,
  //   src: "/images/home/collaborations-miro-martinic.jpg",
  //   alt: labels.homeCollaborationsAlt,
  //   imgTitle: labels.homeCollaborationsImgTitle
  // }
]

export default function Home({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  const items = homeItems(labels, paths(labels), isSmallScreen).filter(
    item => item !== undefined
  )

  return (
    <Page
      {...props}
      labels={labels}
      theme={themes(labels).ipsBlack(false)}
      description={labels.homeDescription}
      keywords={labels.homeKeywords}
      ogUrl={paths(labels).home}
      ogImage="/images/og-images/home.jpg"
      headerPadding={isSmallScreen}
      footerPadding={true}
    >
      <ResponsiveImageCarousel
        images={homeCarousel(labels)}
        className="ips-carousel"
        showIndicators={false}
        mode={isSmallScreen ? "" : "full-screen"}
        imageContainerClass={
          isSmallScreen ? "ips-carousel-image-container" : undefined
        }
      />
      {items
        .map(item => {
          const { text, path, ...imageProps } = item
          return (
            <Link to={path}>
              <div className="ips-item-container">
                <h2>{item.text}</h2>
                <ResponsiveImage {...imageProps} />
              </div>
            </Link>
          )
        })
        .reduce(
          (r, a, i) =>
            r.concat(
              a,
              i < items.length && <div className="ips-item-divider" />
            ),
          []
        )}
      <div className="ips-signature-container">
        <div className="ips-signature-text-container">
          <div className="ips-signature-s-text">
            {labels.homeSignatureSText}
          </div>
          <div>{labels.homeIgorProdanovicStudioSignatureText}</div>
          <div className="ips-signature-year-text">{` ${new Date().getFullYear()}`}</div>
        </div>
      </div>
    </Page>
  )
}
